var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"GK9KLjLpZ7hjv1Rj-dGBd"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const getDSN = () => {
	if (
		['production', 'live'].includes(
			process.env.NEXT_PUBLIC_ENVIRONMENT as string
		)
	) {
		return 'https://712f211dfdd19a03ad539f70d4b8119e@o4506393288179712.ingest.us.sentry.io/4507661391953920'
	}

	return undefined
}

Sentry.init({
	dsn: getDSN(),
	environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
	ignoreErrors: [
		'jQuery',
		'This Suspense boundary received an update before it finished hydrating.',
		'Abort route change. Please ignore this error.',
		'Non-Error promise rejection captured with value',
		'Large HTTP Payload',
		'Event `Event` (type=error) captured as promise rejection',
		'Could not load "overlay".',
		"Cannot read properties of undefined (reading 'liveAgentAPI')",
		"Cannot read properties of undefined (reading 'Domain')",
		"Cannot set properties of undefined (setting 'transform')", // Related to https://github.com/framer/motion/issues/2318
		'WKWebView API client did not respond to this postMessage',
		'Error creating WebGL context.',
	],
	denyUrls: [
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Chatbot
		/.*embeddedservice.*/,
		// Google map
		/maps\/api\/js/,

		'widgetChip.js',

		// Cookie banner
		'otBannerSdk.js',
		'otSDKStub.js',
	],
	beforeSend(event, hint) {
		if (hint.originalException === 'Timeout') {
			return null
		}

		return event
	},

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.05,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	replaysOnErrorSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [],
})
